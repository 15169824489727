/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:2eb7f286-4727-4469-b078-d23778e37d8d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_MMUQlkjBw",
    "aws_user_pools_web_client_id": "27i9stmovlnr4cfp9rbumsnnl5",
    "oauth": {}
};


export default awsmobile;
